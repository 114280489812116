// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_COMMAND = '/';

// ----------------------------------------------------------------------

/* export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
}; */

export const PATH_PAGE = {
  /*   comingSoon: "/coming-soon",*/
  pizzas: '/pizzas',
  pates: '/pates',
  /*   menu: 'https://menu.elitesteakhouse.fr', */
  menu: '/menu',
  reservation: '/reservation.html',
  desserts: '/desserts',
  boissons: '/boissons',
  salades: '/salades',
  tartesflambees: '/tartes-flambees',
  commander: '/',
  mentionsLegales: '/',
  cgv: '/',
  contact: '/',
};
