// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import IcecreamIcon from '@mui/icons-material/Icecream';
import LiquorIcon from '@mui/icons-material/Liquor';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import StorefrontIcon from '@mui/icons-material/Storefront';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import TapasIcon from '@mui/icons-material/Tapas';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Menu',
    icon: <RestaurantIcon {...ICON_SIZE} />,
    //path: 'https://menu.elitesteakhouse.fr',
    path: '/menu',
  },
];

export default menuConfig;
