import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// next
import NextLink from 'next/link';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { m } from 'framer-motion';
import { MotionContainer, varFade } from './animate';
import { height } from '@mui/system';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  const logo = '/logo/logo_elite.png';

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <NextLink href="/">
      <m.img variants={varFade().inRight} src={logo} height="60px" />
    </NextLink>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
