// @mui
import { styled } from '@mui/material/styles';
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
} from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// locale
import useLocales from '../../locales/useLocales';
import Copyright from 'components/drylead';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Société',
    children: [
      { name: 'Mentions légales', href: PATH_PAGE.mentionsLegales },
      { name: 'CGV', href: PATH_PAGE.cgv },
      { name: 'Horaires', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Elite Steak House' },
      { name: '18 Rue du Doubs, 67100 Strasbourg' },
      { name: '03 88 60 26 24' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { translate } = useLocales();
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          color="text.secondary"
          sx={{
            textAlign: { xs: 'justify', md: 'left' },
          }}
        >
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'left' }}
              sx={{ mb: { xs: 1, md: 0 } }}
            >
              <Typography sx={{ mt: 1, textTransform: 'uppercase' }}>
                Elite Steak House{' '}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="body2">{translate('footertext')}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          color="text.secondary"
          sx={{
            textAlign: { xs: 'justify', md: 'left' },
            mb: 2,
          }}
        >
          <Grid item>
            <Typography
              component="p"
              variant="caption"
              color="text.secondary"
              sx={{
                fontSize: 13,
                textAlign: { xs: 'jusify', md: 'left' },
              }}
            >
              © 2021. Tous droits réservés.
            </Typography>
          </Grid>
          <Grid item>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
