import axios from 'axios';
import Env from './env';
const TOKEN =
  Env.environment == 'dev'
    ? process.env.TOKEN_API_DEV
    : process.env.TOKEN_API_PROD;

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: Env.api || '',
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
