const DEV = {
  environment: 'dev',
  api: 'https://admin.elitesteakhouse.fr',
  /*   api: 'http://0.0.0.0:1337', */
  hostnames: {
    protocol: 'http://',
    app: 'localhost:3000',
  },
};

const PROD = {
  environment: 'production',
  api: 'https://admin.elitesteakhouse.fr',
  hostnames: {
    protocol: 'https://',
    app: 'lavenezia.fr',
    website: 'lavenezia.fr',
  },
};

const ENV = process.env.NODE_ENV === 'production' ? PROD : DEV;
export default ENV;
