import React from 'react';
import { Box } from '@mui/material';

const Copyright = () => {
  return (
    <Box
      component="a"
      href="https://drylead.agency"
      target="_blank"
      title="Drylead"
      width={80}
    >
      <Box
        component={'img'}
        src={'https://drylead.agency/logo/logo.png'}
        width={16}
        height={16}
        alt="agence application web et outils seo"
      />
    </Box>
  );
};

export default Copyright;
